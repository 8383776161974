var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "singlePayment" },
    [
      _c(
        "Row",
        { staticStyle: { height: "100%", "flex-wrap": "nowrap" } },
        [
          _vm.expand
            ? _c(
                "Col",
                { style: _vm.leftSpan },
                [
                  _c(
                    "Card",
                    { staticStyle: { height: "100%", overflow: "hidden" } },
                    [
                      _c("Alert", { staticClass: "alert" }, [
                        _vm._v("人员查询"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "RadioGroup",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "margin-left": "5px",
                              },
                              on: { "on-change": _vm.statusChange },
                              model: {
                                value: _vm.passStatus,
                                callback: function ($$v) {
                                  _vm.passStatus = $$v
                                },
                                expression: "passStatus",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "未收费" } }),
                              _c("Radio", { attrs: { label: "已收费" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                            "align-items": "center",
                          },
                        },
                        [
                          _vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "RadioGroup",
                                {
                                  staticClass: "btngroup",
                                  attrs: {
                                    type: "button",
                                    size: "small",
                                    "button-style": "solid",
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                [
                                  _c("Radio", {
                                    attrs: { label: "当日" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.radioClick("当日")
                                      },
                                    },
                                  }),
                                  _c("Radio", {
                                    attrs: { label: "当月" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.radioClick("当月")
                                      },
                                    },
                                  }),
                                  _c(
                                    "DatePicker",
                                    {
                                      attrs: {
                                        open: _vm.datePickerShow,
                                        transfer: "",
                                        type: "daterange",
                                      },
                                      on: { "on-change": _vm.datePickerChange },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          [
                                            _c("Radio", {
                                              attrs: { label: "自定义" },
                                              nativeOn: {
                                                click: [
                                                  function ($event) {
                                                    _vm.datePickerShow =
                                                      !_vm.datePickerShow
                                                  },
                                                  function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.radioClick(
                                                      "自定义"
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "span",
                                {
                                  staticClass: "resetBtn",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.resetClick },
                                },
                                [_vm._v("重置")]
                              )
                            : _vm._e(),
                          !_vm.queryTime &&
                          (_vm.passStatus == "已登记" ||
                            _vm.feeStatus == "已确认")
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "nowrap",
                                    "align-items": "center",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c("DatePicker", {
                                    attrs: {
                                      type: "date",
                                      transfer: "",
                                      placeholder: "开始时间",
                                    },
                                    on: {
                                      "on-change": _vm.datePickerStartTime,
                                    },
                                    model: {
                                      value: _vm.searchForm.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startDate",
                                    },
                                  }),
                                  _vm._v("  ~  "),
                                  _c("DatePicker", {
                                    attrs: {
                                      type: "date",
                                      transfer: "",
                                      placeholder: "结束时间",
                                    },
                                    on: { "on-change": _vm.datePickerEndTime },
                                    model: {
                                      value: _vm.searchForm.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endDate", $$v)
                                      },
                                      expression: "searchForm.endDate",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("Input", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { placeholder: "请输入姓名", clearable: "" },
                        on: {
                          "on-clear": function ($event) {
                            return _vm.inputSearchChange("")
                          },
                        },
                        nativeOn: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.inputSearchChange.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.personName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "personName", $$v)
                          },
                          expression: "searchForm.personName",
                        },
                      }),
                      !_vm.queryTime
                        ? _c("Input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drop,
                                expression: "drop",
                              },
                            ],
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "idCard", $$v)
                              },
                              expression: "searchForm.idCard",
                            },
                          })
                        : _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "idCard", $$v)
                              },
                              expression: "searchForm.idCard",
                            },
                          }),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入体检编号",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.testNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "testNum", $$v)
                              },
                              expression: "searchForm.testNum",
                            },
                          })
                        : _vm._e(),
                      _vm.drop
                        ? _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              placeholder: "请输入单位名称",
                              clearable: "",
                            },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.searchForm.dept,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "dept", $$v)
                              },
                              expression: "searchForm.dept",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.inputSearchChange },
                        },
                        [_vm._v("查询")]
                      ),
                      !_vm.queryTime &&
                      (_vm.passStatus == "已登记" || _vm.feeStatus == "已确认")
                        ? _c(
                            "Button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.resetClick },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                          _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                        ],
                        1
                      ),
                      _vm.personData.length > 0
                        ? _c(
                            "Row",
                            {
                              staticStyle: { "justify-content": "center" },
                              style: {
                                height:
                                  _vm.passStatus == "已登记"
                                    ? "calc(100% - " + _vm.height1 + ")"
                                    : "calc(100% - " + _vm.height2 + ")",
                              },
                            },
                            [
                              _c(
                                "RadioGroup",
                                {
                                  staticClass: "radio-group",
                                  model: {
                                    value: _vm.isRadioChecked,
                                    callback: function ($$v) {
                                      _vm.isRadioChecked = $$v
                                    },
                                    expression: "isRadioChecked",
                                  },
                                },
                                _vm._l(_vm.personData, function (item, index) {
                                  return _c(
                                    "Row",
                                    {
                                      key: index,
                                      staticClass: "row-border",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.radioChooseClick(
                                            item.id,
                                            item.registDate
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-radio",
                                          attrs: { span: 4 },
                                        },
                                        [
                                          _c("Radio", {
                                            attrs: {
                                              label: item.id,
                                              disabled: _vm.showRadio,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-content",
                                          attrs: { span: 15 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "80px",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                                "white-space": "nowrap",
                                              },
                                              attrs: { title: item.personName },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.personName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.sex))]
                                          ),
                                          _c("div", [_vm._v(_vm._s(item.age))]),
                                        ]
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 5 } },
                                        [
                                          _c(
                                            "Row",
                                            { attrs: { gutter: 6 } },
                                            [
                                              _c("Col", {
                                                attrs: { span: 12 },
                                              }),
                                              _c(
                                                "Col",
                                                { attrs: { span: 12 } },
                                                [
                                                  item.sporadicPhysical &&
                                                  item.sporadicPhysical == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 零 ")]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 团 ")]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _vm.personData.length > 0
                                ? _c("Page", {
                                    attrs: {
                                      current: _vm.searchForm.pageNumber,
                                      total: _vm.personTotal,
                                      simple: "",
                                    },
                                    on: {
                                      "on-change": _vm.personChangePageNum,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.personData.length == 0 ? _c("no-data") : _vm._e(),
                      _vm.checkPersonLoading
                        ? _c(
                            "Spin",
                            { attrs: { fix: "" } },
                            [
                              _c("Icon", {
                                staticClass: "demo-spin-icon-load",
                                attrs: { type: "ios-loading", size: "18" },
                              }),
                              _c("div", [_vm._v("加载中...")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            {
              staticStyle: { height: "100%", overflow: "hidden" },
              style: _vm.span,
            },
            [
              _c(
                "Card",
                [
                  _c(
                    "Form",
                    {
                      ref: "personParam",
                      staticClass: "personForm",
                      attrs: {
                        model: _vm.personParam,
                        rules: _vm.personParamRule,
                        "in-line": "",
                        "label-width": 80,
                      },
                    },
                    [
                      _c(
                        "Row",
                        {
                          staticStyle: { "flex-wrap": "nowrap" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "Col",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "space-between",
                              },
                              attrs: { span: "21" },
                            },
                            [
                              _c(
                                "Row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "体检编号" } },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "提交后系统自动生成",
                                              clearable: "",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.personParam.testNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "testNum",
                                                  $$v
                                                )
                                              },
                                              expression: "personParam.testNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "身份证号",
                                            prop: "idCard",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              readonly:
                                                _vm.searchForm.isPass == 99
                                                  ? true
                                                  : false,
                                              placeholder: "请输入身份证号",
                                            },
                                            on: { "on-blur": _vm.idCardChange },
                                            model: {
                                              value: _vm.personParam.idCard,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "idCard",
                                                  $$v
                                                )
                                              },
                                              expression: "personParam.idCard",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: _vm.setLabel("姓名"),
                                            prop: "personName",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              readonly:
                                                _vm.searchForm.isPass == 99
                                                  ? true
                                                  : false,
                                              placeholder: "请输入姓名",
                                            },
                                            model: {
                                              value: _vm.personParam.personName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "personName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "personParam.personName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: _vm.setLabel("性别"),
                                            prop: "sex",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "性别",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm.personParam.sex,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "sex",
                                                  $$v
                                                )
                                              },
                                              expression: "personParam.sex",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: _vm.setLabel("年龄"),
                                            prop: "age",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "年龄",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm.personParam.age,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "age",
                                                  $$v
                                                )
                                              },
                                              expression: "personParam.age",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "体检类型",
                                            prop: "physicalType",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "体检类型",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _vm.personParam.physicalType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "physicalType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "personParam.physicalType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "联系电话",
                                            prop: "mobile",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              readonly:
                                                _vm.searchForm.isPass == 99
                                                  ? true
                                                  : false,
                                              placeholder: "联系电话",
                                            },
                                            model: {
                                              value: _vm.personParam.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personParam,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression: "personParam.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 6 } },
                                    [
                                      _vm.isUpdateUnitName
                                        ? _c(
                                            "FormItem",
                                            { attrs: { label: "单位名称" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticClass: "input",
                                                  attrs: {
                                                    filterable: true,
                                                    readonly:
                                                      _vm.searchForm.isPass ==
                                                      99
                                                        ? true
                                                        : false,
                                                    "label-in-value": "",
                                                    transfer: "",
                                                  },
                                                  on: {
                                                    "on-select":
                                                      _vm.onOrderSelectChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.unitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "unitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.unitId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.unitArr,
                                                  function (item, index) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: item.id,
                                                          tag: item.groupUnitId,
                                                          label: item.name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isUpdateUnitNameGroup
                                        ? _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "单位名称",
                                                prop: "unitId",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticClass: "input",
                                                  attrs: {
                                                    filterable: true,
                                                    readonly:
                                                      _vm.searchForm.isPass ==
                                                      99
                                                        ? true
                                                        : false,
                                                    "label-in-value": "",
                                                    transfer: "",
                                                  },
                                                  on: {
                                                    "on-select":
                                                      _vm.onOrderSelectChangeGroup,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.unitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "unitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.unitId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.orderArr,
                                                  function (item, index) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: item.id,
                                                          label:
                                                            item.groupUnitName,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.groupUnitName +
                                                                "-" +
                                                                item.orderCode
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isUpdateUnitName &&
                                      !_vm.isUpdateUnitNameGroup
                                        ? _c(
                                            "FormItem",
                                            { attrs: { label: "单位名称" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  readonly: true,
                                                  placeholder: "单位名称",
                                                },
                                                model: {
                                                  value: _vm.personParam.dept,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "dept",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.dept",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "6" } },
                                    [
                                      _vm.personParam.sporadicPhysical &&
                                      _vm.personParam.sporadicPhysical == "1"
                                        ? _c(
                                            "FormItem",
                                            { attrs: { label: "分组名称" } },
                                            [
                                              _c("Input", {
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "请输入分组名称",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personParam.groupName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personParam,
                                                      "groupName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personParam.groupName",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "FormItem",
                                            { attrs: { label: "分组名称" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticClass: "input",
                                                  attrs: {
                                                    placeholder: "请先选择单位",
                                                    filterable: true,
                                                    clearable: "",
                                                    readonly:
                                                      _vm.searchForm.isPass ==
                                                      99
                                                        ? true
                                                        : false,
                                                    "label-in-value": "",
                                                    transfer: "",
                                                  },
                                                  on: {
                                                    "on-clear":
                                                      _vm.clearGroupId,
                                                    "on-select":
                                                      _vm.changeGroup,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personParam.groupId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personParam,
                                                        "groupId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personParam.groupId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.groupByOrderData,
                                                  function (item, index) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: item.id,
                                                          tag: item,
                                                          label: item.name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                  _vm.passStatus == "已登记"
                                    ? _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "登记时间" } },
                                            [
                                              _c("Input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value:
                                                    _vm.personInfo
                                                      .regist_date ||
                                                    _vm.personInfo.registDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo
                                                        .regist_date ||
                                                        _vm.personInfo,
                                                      "registDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.regist_date || personInfo.registDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "Col",
                            [
                              _vm.passStatus === "未收费"
                                ? _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "110px" },
                                      attrs: {
                                        type: "success",
                                        icon: "ios-clipboard",
                                      },
                                      on: { click: _vm.updateIsPass },
                                    },
                                    [_vm._v("确认缴费")]
                                  )
                                : _vm._e(),
                              _c(
                                "Button",
                                {
                                  staticStyle: { width: "110px" },
                                  attrs: {
                                    type: "success",
                                    icon: "ios-clipboard",
                                  },
                                  on: { click: _vm.printReceipt },
                                },
                                [_vm._v("打印缴费单")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Card",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    height: "calc(100% - 241px)",
                    overflow: "auto",
                  },
                },
                [
                  _c(
                    "Form",
                    [
                      _c(
                        "Row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "Col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "套餐内费用：" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-size": "18px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "￥" +
                                          _vm._s(this.orderInfo.packageTotal) +
                                          "元"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检费用合计：" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-size": "18px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "￥" +
                                          _vm._s(this.orderInfo.orderTotal) +
                                          "元"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            {
                              staticStyle: { display: "flex" },
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "FormItem",
                                {
                                  staticStyle: {
                                    color: "blue",
                                    "font-size": "18px",
                                  },
                                  attrs: { label: "优惠价格：" },
                                },
                                [
                                  _c("InputNumber", {
                                    staticStyle: {
                                      color: "blue",
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                      "white-space": "nowrap",
                                    },
                                    attrs: { min: 0 },
                                    model: {
                                      value: _vm.tGroupData.discountPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupData,
                                          "discountPrice",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupData.discountPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Alert",
                    {
                      staticClass: "ivu-alert-left",
                      staticStyle: { height: "40px", "line-height": "23px" },
                    },
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v("体检项目"),
                      ]),
                    ]
                  ),
                  _c("Table", {
                    ref: "table",
                    attrs: {
                      loading: _vm.projectLoading,
                      border: "",
                      columns: _vm.projectColumns,
                      sortable: "custom",
                      data: _vm.groupData[0].projectData,
                      "max-height": _vm.tableHeight,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("payment-receipt", {
            ref: "paymentReceipt",
            attrs: {
              personInfo: _vm.personInfo,
              projectInfo: _vm.data,
              orderTotal: _vm.orderInfo.orderTotal,
              payType: 2,
              isReviewer: _vm.isReviewer,
              passStatus: _vm.passStatus,
              data: _vm.data,
            },
            model: {
              value: _vm.printSheet,
              callback: function ($$v) {
                _vm.printSheet = $$v
              },
              expression: "printSheet",
            },
          }),
          _c("chooseWorkType", {
            on: { workCodeReback: _vm.workCodeReback },
            model: {
              value: _vm.workShow,
              callback: function ($$v) {
                _vm.workShow = $$v
              },
              expression: "workShow",
            },
          }),
          _c("harmDrawer", {
            attrs: {
              modalTitle: "危害因素选择",
              selectedHarmList: _vm.selectedHarmList,
              selectType: _vm.seltType,
            },
            on: { harmReback: _vm.harmReback },
            model: {
              value: _vm.harmShow,
              callback: function ($$v) {
                _vm.harmShow = $$v
              },
              expression: "harmShow",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }